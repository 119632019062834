import React from 'react';

class Selected extends React.Component {
	updateCount = () => {
		const selectedCount =
			this.props.selectedTags.length + this.props.tempTags.length;
		return selectedCount;
	};

	counterExceeded = () => {
		if (this.updateCount() > 30) {
			return true;
		} else {
			return false;
		}
	};

	copyToClipboard = () => {
		var copyText = document.querySelector('.selectedTags');
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand('copy');
	};

	render() {
		const selectedTags = this.props.selectedTags;
		let selectedTagListNames = selectedTags.map((a) => a.tag);

		const allTags = this.props.allTags;

		const filteredTags = Object.entries(allTags).filter((i) =>
			selectedTagListNames.includes(i[1].uid)
		);

		console.log(this.props.tempTags);
		// console.log(selectedTagListNames);

		let selectedTagNames = [];
		filteredTags.map((item) => {
			selectedTags.map((tag) => {
				if (tag.tag === item[1].uid) {
					selectedTagNames.push({
						tag: item[1].tag,
						timeSelected: tag.timeSelected,
						favourite: item[1].favourite,
					});
				}
			});
		});

		let mergedTags = selectedTagNames.concat(this.props.tempTags);
		console.log(mergedTags);
		selectedTagNames = mergedTags.sort((a, b) =>
			a.favourite < b.favourite
				? 1
				: a.favourite === b.favourite
				? a.timeSelected > b.timeSelected
					? 1
					: -1
				: -1
		);

		let selectedTagsNameOnly = [];
		selectedTagNames.map((tagName) => {
			selectedTagsNameOnly.push(tagName.tag);
		});
		const selectedTagsText = selectedTagsNameOnly.join(' ');

		return (
			<div className="section">
				<h2>Selected Tags</h2>
				<textarea
					readOnly
					className="selectedTags"
					value={selectedTagsText}
				/>
				<ul className="selectedBtns">
					<li>
						<button onClick={(e) => this.copyToClipboard(e)}>
							Copy to clipboard
						</button>
					</li>
					<li>
						<button onClick={() => this.props.clearSelected()}>
							Clear All Selected
						</button>
					</li>
					<li className="tagCount">
						Tag Count:
						<span
							className={
								this.counterExceeded() ? 'exceeded' : 'goodtogo'
							}
						>
							{this.updateCount()}
						</span>
					</li>
				</ul>
			</div>
		);
	}
}

export default Selected;
