import React from 'react';
import ReactDOM from 'react-dom';

import './css/index.scss';

import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';

ReactDOM.render(
	<FirebaseContext.Provider value={new Firebase()}>
		<App />
	</FirebaseContext.Provider>,
	document.querySelector('#main')
);
