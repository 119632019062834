import React, { Component } from 'react';
import { withFirebase } from '../Firebase';

import Selected from './selected';

import AddTagForm from './AddTagForm';
import AddTempTag from './AddTempTag';
import Search from './search';
import SimilarTags from './SimilarTags';

import TagList from './taglist';

import axiosWithDelimiterFile from '../../shared/axios-throttled.js';

class Landing extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			hashtags: [],
			categories: [],
			isUpdating: false,
			updateCount: 0,
			selectedTags:
				JSON.parse(localStorage.getItem('selectedTags')) || [],
			tempTags: JSON.parse(localStorage.getItem('tempTags')) || [],
		};
	}

	addTag = async (tag) => {
		const hashtags = this.state.hashtags;

		if (hashtags.some((e) => e.tag === tag.tag)) {
			console.log('Matching!');
			return 'Error: Tag Exists';
		} else {
			this.props.firebase.hashtags().push(tag);
			return 'Success';
		}

		// this.props.firebase.hashtags().push(tag);
	};

	searchTag = async (tag) => {
		// console.log(tag);
		var searches = [];
		if (localStorage.getItem('searchTags')) {
			searches = JSON.parse(localStorage.getItem('searchTags'));
		}

		searches.push(tag);
		console.log(searches);
		localStorage.setItem('searchTags', JSON.stringify(searches));
	};

	similarTags = async (searchedTag, similarTags) => {
		console.log(searchedTag);
		console.log(similarTags);
		localStorage.setItem(
			'similarTags_searchedTag',
			JSON.stringify(searchedTag)
		);

		localStorage.setItem(
			'similarTags_results',
			JSON.stringify(similarTags)
		);
	};

	addFavourite = async (key) => {
		const hashtags = { ...this.state.hashtags };

		hashtags[key].favourite = !hashtags[key].favourite;
		this.setState({ hashtags: hashtags });
		var id = hashtags[key].uid;
		this.props.firebase.hashtags().child(id).update({
			favourite: hashtags[key].favourite,
		});
	};

	updateItems = async (tags) => {
		const requests = tags.map((tag) => {
			return this.getTagCount(tag);
		});
		return Promise.all(requests);
	};

	onUpdateItem = async (tag) => {
		console.log('Starting Update');
		this.setState({ isUpdating: true });
		this.updateItems([tag])
			.then((a) =>
				a.map((item) => {
					return this.props.firebase
						.hashtags()
						.child(item.tag.uid)
						.update({
							count: item.count,
						});
				})
			)
			.then((a) => this.setState({ isUpdating: false }))
			.then((a) => console.log('Update Ended'));
	};

	onUpdateItems = async () => {
		console.log('Starting Update');
		this.setState({ isUpdating: true });
		const tags = this.state.hashtags;
		this.updateItems(tags)
			.then((a) =>
				a.map((item) => {
					return this.props.firebase
						.hashtags()
						.child(item.tag.uid)
						.update({
							count: item.count,
						});
				})
			)
			.then((a) => this.setState({ isUpdating: false }))
			.then((a) => console.log('Update Ended'));
	};

	async getTagCount(tag) {
		try {
			const tagname = tag.tag.replace('#', '');
			const url = `https://www.instagram.com/explore/tags/${tagname}/?__a=1`;
			const { data } = await axiosWithDelimiterFile.get(url);

			return {
				tag: tag,
				count: data.graphql.hashtag.edge_hashtag_to_media.count,
			};
		} catch (error) {
			console.log(error.message);
		}
	}

	clearSelected = async () => {
		let selectedTags = [];
		let tempTags = [];
		this.setState({ selectedTags });
		this.setState({ tempTags });
		localStorage.setItem('selectedTags', JSON.stringify(selectedTags));
		localStorage.setItem('tempTags', JSON.stringify(tempTags));
	};

	selectTag = (tag) => {
		let selectedTags = [...this.state.selectedTags];

		// Add item to it
		selectedTags.push({ tag: tag, timeSelected: Date.now() });

		// Set state
		this.setState({ selectedTags });
		localStorage.setItem('selectedTags', JSON.stringify(selectedTags));
	};

	selectTempTag = (tag) => {
		let tempTags = [...this.state.tempTags];

		// Add item to it
		tempTags.push({ tag: tag, timeSelected: Date.now(), favourite: false });
		this.setState({ tempTags });
		localStorage.setItem('tempTags', JSON.stringify(tempTags));
	};

	deselectTag = (tag) => {
		let selectedTags = [...this.state.selectedTags];
		console.log(selectedTags);
		// Add item to it

		for (var i = selectedTags.length - 1; i >= 0; i--) {
			if (selectedTags[i].tag === tag) {
				selectedTags.splice(i, 1);
			}
		}
		console.log(selectedTags);

		// selectedTags.push(tag);

		// Set state
		this.setState({ selectedTags });
		localStorage.setItem('selectedTags', JSON.stringify(selectedTags));
	};

	componentDidMount() {
		this.setState({ loading: true });

		this.props.firebase.hashtags().on('value', (snapshot) => {
			const hashtagsObject = snapshot.val();
			const tagList = Object.keys(hashtagsObject).map((key) => ({
				...hashtagsObject[key],
				uid: key,
			}));
			const uniqueCats = [...new Set(tagList.map((x) => x.category))];
			this.setState({
				hashtags: tagList,
				loading: false,
				categories: uniqueCats,
			});
		});

		this.setupBeforeUnloadListener();
	}

	doSomethingBeforeUnload = () => {
		localStorage.removeItem('similarTags_results');
		localStorage.removeItem('similarTags_searchedTag');
	};

	// Setup the `beforeunload` event listener
	setupBeforeUnloadListener = () => {
		window.addEventListener('beforeunload', (ev) => {
			ev.preventDefault();
			return this.doSomethingBeforeUnload();
		});
	};

	componentWillUnmount() {
		this.props.firebase.hashtags().off();
	}

	render() {
		const {
			hashtags,
			loading,
			categories,
			isUpdating,
			updateCount,
			selectedTags,
			tempTags,
		} = this.state;

		var updateCounter;
		if (isUpdating) {
			updateCounter = (
				<p>
					Loading - <span>{updateCount}</span>/{hashtags.length}
				</p>
			);
		}
		return loading ? (
			<div>Loading...</div>
		) : (
			<div>
				<Selected
					selectedTags={selectedTags}
					allTags={hashtags}
					clearSelected={this.clearSelected}
					tempTags={tempTags}
				/>

				<TagList
					categories={categories}
					hashtags={hashtags}
					addFavourite={this.addFavourite}
					selectTag={this.selectTag}
					deselectTag={this.deselectTag}
					selectedTags={selectedTags}
					isUpdating={isUpdating}
					updateCounter={updateCounter}
					updateItems={this.onUpdateItems}
					updateItem={this.onUpdateItem}
				/>
				<AddTempTag
					selectTempTag={this.selectTempTag}
					updateCounter={updateCounter}
				/>
				<AddTagForm addTag={this.addTag} />
				<Search searchTag={this.searchTag} />
				<SimilarTags similarTags={this.similarTags} />
			</div>
		);
	}
}

export default withFirebase(Landing);
