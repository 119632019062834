import React from 'react';
import axios from 'axios';
import { AuthUserContext } from '../Session';

const INITIAL_STATE = {
	tag: '',
	category: '',
	favourite: false,
	error: null,
	message: null,
};
class AddTagForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}

	tagRef = React.createRef();
	categoryRef = React.createRef();
	favouriteRef = React.createRef();

	createTag = async (event) => {
		const { tag, category, favourite } = this.state;
		event.preventDefault();
		const data = await this.getTagData(tag).catch((error) => {
			this.setState({ error });
		});
		const tagCount = data.edge_hashtag_to_media.count;
		let tagName = tag;
		// let tagName = this.tagRef.current.value;
		if (tagName.charAt(0) !== '#') {
			tagName = '#' + tagName;
		}

		const tagItem = {
			tag: tagName,
			category: category,
			favourite: favourite,
			count: tagCount,
		};

		let result = await this.props.addTag(tagItem);
		this.setState({ ...INITIAL_STATE });

		console.log(result);
		if (result === 'Success') {
			this.setState({
				message: `Success! Added ${tagName} to ${category}`,
			});
		} else if (result === 'Error: Tag Exists') {
			this.setState({
				error: {
					message: 'Error: Tag already exists!',
				},
			});
		} else {
			this.setState({
				error: {
					message: 'Error: Unknown Error Occurred!',
				},
			});
		}
	};

	async getTagData(tag) {
		const tagname = tag.replace('#', '');
		const url = `https://www.instagram.com/explore/tags/${tagname}/?__a=1`;
		const { data } = await axios.get(url);
		return data.graphql.hashtag;
	}

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleCheckboxChange = (event) =>
		this.setState({ favourite: event.target.checked });

	render() {
		const { tag, category, error, message } = this.state;
		const isInvalid = tag === '' || category === '';
		return (
			<AuthUserContext.Consumer>
				{(authUser) =>
					authUser ? (
						<div className="section">
							<h2>Add Tag</h2>
							<form
								className="addTagForm"
								onSubmit={this.createTag}
							>
								<input
									name="tag"
									ref={this.tagRef}
									onChange={this.onChange}
									type="text"
									placeholder="Tag"
									value={this.state.tag}
								/>
								<input
									name="category"
									ref={this.categoryRef}
									onChange={this.onChange}
									type="text"
									placeholder="Category"
									value={this.state.category}
								/>

								<label>
									<input
										type="checkbox"
										name="favourite"
										onChange={this.handleCheckboxChange}
										ref={this.favouriteRef}
										checked={this.state.favourite}
									/>
									Add to favourites
								</label>
								<br />
								<button type="submit" disabled={isInvalid}>
									Add Tag
								</button>

								{error && (
									<p className="error">{error.message}</p>
								)}
								{message && (
									<p className="success">{message}</p>
								)}
							</form>
						</div>
					) : null
				}
			</AuthUserContext.Consumer>
		);
	}
}

export default AddTagForm;
