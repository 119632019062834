import React from 'react';
import axios from 'axios';
import { numberWithCommas } from '../../shared/utils';

const INITIAL_STATE = {
	tag: '',
	error: null,
};
class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}

	tagRef = React.createRef();

	searchTag = async (event) => {
		const { tag } = this.state;
		event.preventDefault();
		const data = await this.getTagData(tag).catch((error) => {
			this.setState({ error });
		});
		const tagCount = data.edge_hashtag_to_media.count;
		let tagName = tag;
		if (tagName.charAt(0) !== '#') {
			tagName = '#' + tagName;
		}

		const tagItem = {
			tag: tagName,
			count: tagCount,
		};

		this.props.searchTag(tagItem);
		this.setState({ ...INITIAL_STATE });
	};

	async getTagData(tag) {
		const tagname = tag.replace('#', '');
		const url = `https://www.instagram.com/explore/tags/${tagname}/?__a=1`;
		const { data } = await axios.get(url);
		return data.graphql.hashtag;
	}

	clearSearches = () => {
		localStorage.setItem('searchTags', []);
		this.setState({ ...INITIAL_STATE });
	};

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	render() {
		const { tag, error } = this.state;
		const isInvalid = tag === '';

		const prevSearches = localStorage.getItem('searchTags')
			? JSON.parse(localStorage.getItem('searchTags'))
			: null;

		return (
			<div className="section">
				<h2>Search for Tag</h2>
				<form className="searchTagForm" onSubmit={this.searchTag}>
					<input
						name="tag"
						ref={this.tagRef}
						onChange={this.onChange}
						type="text"
						placeholder="Tag"
						value={this.state.tag}
					/>

					<button type="submit" disabled={isInvalid}>
						Search Tag
					</button>
				</form>
				{prevSearches ? (
					<>
						<h3>Previous Searches</h3>
						<ul>
							{prevSearches.map((item) => (
								<li key={`${item.tag}${Date.now()}`}>
									{item.tag} - {numberWithCommas(item.count)}
								</li>
							))}
						</ul>
						<button onClick={() => this.clearSearches()}>
							Clear Previous Searches
						</button>
					</>
				) : null}
			</div>
		);
	}
}

export default Search;
