import React from 'react';
import { numberWithCommas } from '../../shared/utils';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';

const FavButton = ({ addFavourite, index }) => (
	<AuthUserContext.Consumer>
		{(authUser) =>
			authUser ? (
				<>
					<button
						className="favourite"
						onClick={() => addFavourite(index)}
					>
						★
					</button>
				</>
			) : null
		}
	</AuthUserContext.Consumer>
);

const UpdateButton = ({ updateItem, tag, isUpdating }) => (
	<AuthUserContext.Consumer>
		{(authUser) =>
			authUser && !!authUser.roles[ROLES.ADMIN] ? (
				<>
					<button
						disabled={isUpdating}
						className="updateButton"
						onClick={() => updateItem(tag)}
					>
						{isUpdating ? '⤫' : '↻'}
					</button>
				</>
			) : null
		}
	</AuthUserContext.Consumer>
);

class Hashtag extends React.Component {
	selectHandler = (e, selected) => {
		this.props.selectTag(this.props.uid);
		console.log(selected);
		if (selected === true) {
			console.log('Removed');
			this.props.deselectTag(this.props.uid);
		} else {
			console.log('Selected');
			this.props.selectTag(this.props.uid);
		}
	};

	render() {
		const count = this.props.tag.count;
		const selectedTags = this.props.selectedTags;

		let selectedTagsNames = [];
		selectedTags.map((tagName) => {
			selectedTagsNames.push(tagName.tag);
		});

		const tagUID = this.props.tag.uid;
		const favourite = this.props.tag.favourite;
		var selected;
		var n = selectedTagsNames.includes(tagUID);
		selected = n;
		const isAvailable = selected === false;
		const isFavourite = favourite === false;

		return (
			<li
				key={this.props.index}
				className={
					(isAvailable ? ' unselected' : ' selected') +
					(isFavourite ? ' notfavourited' : ' favourited')
				}
			>
				{this.props.tag.tag}{' '}
				{count && (
					<span className="tagCountLabel">
						{numberWithCommas(count)}
					</span>
				)}
				<FavButton
					addFavourite={this.props.addFavourite}
					index={this.props.index}
				/>
				<UpdateButton
					updateItem={this.props.updateItem}
					tag={this.props.tag}
					isUpdating={this.props.isUpdating}
				/>
				<button
					className="available"
					onClick={(e) => this.selectHandler(e, selected)}
				>
					{isAvailable ? '+' : '✗'}
				</button>
			</li>
		);
	}
}

export default Hashtag;
