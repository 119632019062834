import React from 'react';

const INITIAL_STATE = {
	tag: '',
	error: null,
};

class AddTempTag extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}

	tagRef = React.createRef();

	addTempTag = async (event) => {
		const { tag } = this.state;
		event.preventDefault();

		let tagName = tag;
		if (tagName.charAt(0) !== '#') {
			tagName = '#' + tagName;
		}

		this.props.selectTempTag(tagName);
		this.setState({ ...INITIAL_STATE });
	};

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		return (
			<div className="section">
				<h2>Add Temp Tag</h2>
				<form className="searchTagForm" onSubmit={this.addTempTag}>
					<input
						name="tag"
						ref={this.tagRef}
						onChange={this.onChange}
						type="text"
						placeholder="Tag"
						value={this.state.tag}
					/>

					<button type="submit">Add Temp Tag</button>
				</form>
			</div>
		);
	}
}

export default AddTempTag;
