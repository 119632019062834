import React from 'react';
import { AuthUserContext } from '../Session';

import * as ROLES from '../../constants/roles';

import Category from './category';

const UpdateButton = ({ isUpdating, updateCounter, updateItems }) => (
	<AuthUserContext.Consumer>
		{(authUser) =>
			authUser && !!authUser.roles[ROLES.ADMIN] ? (
				<>
					<button
						className="updateButton"
						disabled={isUpdating}
						onClick={() => updateItems()}
					>
						{isUpdating
							? 'Updating - Please Wait..'
							: 'Update counts'}
					</button>
					{updateCounter}
				</>
			) : null
		}
	</AuthUserContext.Consumer>
);

class TagList extends React.Component {
	render() {
		return (
			<div className="section tagList">
				<h2>Tag List</h2>
				<ul className="tags">
					<Category
						key="favourites"
						index="favourites"
						category="favourites"
						tags={this.props.hashtags}
						addFavourite={this.props.addFavourite}
						selectTag={this.props.selectTag}
						deselectTag={this.props.deselectTag}
						selectedTags={this.props.selectedTags}
						updateItem={this.props.updateItem}
					/>
					{this.props.categories.map((x) => (
						<Category
							key={x}
							index={x}
							category={x}
							tags={this.props.hashtags}
							addFavourite={this.props.addFavourite}
							selectTag={this.props.selectTag}
							deselectTag={this.props.deselectTag}
							selectedTags={this.props.selectedTags}
							updateItem={this.props.updateItem}
							isUpdating={this.props.isUpdating}
						/>
					))}
				</ul>
				<UpdateButton
					isUpdating={this.props.isUpdating}
					updateCounter={this.props.updateCounter}
					updateItems={this.props.updateItems}
				/>
			</div>
		);
	}
}

export default TagList;
