import React from 'react';
import axios from 'axios';
import { numberWithCommas } from '../../shared/utils';

const INITIAL_STATE = {
	tag: '',
	error: null,
};
class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}

	tagRef = React.createRef();

	searchTag = async (event) => {
		const { tag } = this.state;
		event.preventDefault();
		const data = await this.getTagData(tag).catch((error) => {
			this.setState({ error });
		});
		//console.log(data);
		// const tagCount = data.edge_hashtag_to_media.count;
		// let tagName = tag;
		// if (tagName.charAt(0) !== '#') {
		// 	tagName = '#' + tagName;
		// }

		let tagArray = [];

		data.forEach((element) => {
			const tagItem = {
				tag: element.hashtag.name,
				count: element.hashtag.media_count,
			};
			tagArray.push(tagItem);
		});

		this.props.similarTags(tag, tagArray);
		this.setState({ ...INITIAL_STATE });
	};

	async getTagData(tag) {
		const tagname = tag.replace('#', '');
		const url = `https://www.instagram.com/web/search/topsearch/?context=blended&query=%23${tagname}`;
		const { data } = await axios.get(url);
		return data.hashtags;
	}

	clearSearches = () => {
		localStorage.setItem('similarTags', []);
		this.setState({ ...INITIAL_STATE });
	};

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	render() {
		const { tag, error } = this.state;
		const isInvalid = tag === '';

		const searchedTag = localStorage.getItem('similarTags_searchedTag')
			? JSON.parse(localStorage.getItem('similarTags_searchedTag'))
			: null;
		const tagResults = localStorage.getItem('similarTags_results')
			? JSON.parse(localStorage.getItem('similarTags_results'))
			: null;

		return (
			<div className="section">
				<h2>Search for Similar Tags</h2>
				<form className="searchTagForm" onSubmit={this.searchTag}>
					<input
						name="tag"
						ref={this.tagRef}
						onChange={this.onChange}
						type="text"
						placeholder="Tag"
						value={this.state.tag}
					/>

					<button type="submit" disabled={isInvalid}>
						Search Tag
					</button>
				</form>

				{searchedTag && tagResults ? (
					<>
						<h3>Similar Tags for {searchedTag}</h3>
						<ul>
							{tagResults.map((item) => (
								<li key={`${item.tag}${Date.now()}`}>
									{item.tag} - {numberWithCommas(item.count)}
								</li>
							))}
						</ul>
						{/* <button onClick={() => this.clearSearches()}>
							Clear Previous Searches
						</button> */}
					</>
				) : null}
			</div>
		);
	}
}

export default Search;
