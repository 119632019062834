import React from 'react';
import Hashtag from './hashtag';

class Category extends React.Component {
	collapseCat = (e) => {
		if (e.target.className.includes('collapsible-open')) {
			e.target.className = 'listCategory collapsible collapsible-closed';
		} else if (e.target.className.includes('collapsible-closed')) {
			e.target.className = 'listCategory collapsible collapsible-open';
		}
	};

	render() {
		const category = this.props.category;

		return (
			<li key={category} className={this.props.category}>
				<button
					type="button"
					className="listCategory collapsible collapsible-closed"
					onClick={(e) => this.collapseCat(e)}
				>
					{category ? category : 'other'}
				</button>

				<ul>
					{Object.keys(this.props.tags)
						.filter((key) =>
							this.props.category === 'favourites'
								? this.props.tags[key].favourite === true
								: this.props.tags[key].category === category
						)
						.map((key) => (
							<Hashtag
								key={key}
								index={key}
								uid={this.props.tags[key].uid}
								tag={this.props.tags[key]}
								addFavourite={this.props.addFavourite}
								selectTag={this.props.selectTag}
								deselectTag={this.props.deselectTag}
								selectedTags={this.props.selectedTags}
								updateItem={this.props.updateItem}
								isUpdating={this.props.isUpdating}
							/>
						))}
				</ul>
			</li>
		);
	}
}

export default Category;
